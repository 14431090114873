#root{
    padding: 0;
    transition: ease-in-out 0.1s 0s;

    position: relative;
    margin-left: auto;
    margin-right: auto;

    width: 77%;
}

.image{
    width: 100%;

    margin: 0 !important;
    padding: 0 !important;
    box-shadow: 0px 5px 5px -5px #1a1a1a;
    z-index: 0;
}

.description{
    position: absolute;
    bottom: 0;

    margin-right: auto;
    margin-left: auto;

    display: flex;
    flex-direction: column;

}

.description>p{
    color: #BEBEBE;
    margin-top: 0;
    margin-left: 14px;
    font-size: 12pt;
    text-align: left;

    font-family: Merriweather Sans;
}

.description>h3{
    color: #DADADA;
    font-size: 18pt;
    margin-bottom: 5px;
    text-align: left;
    margin-left: 14px;

    font-family: Merriweather;
}

#root:hover{
    transform: scale(1.02);
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    #root{
        width: 90%;
    }  
}

@media only screen and (max-width: 600px) {

    #root{
        width: 100%;
        margin: 0;
        padding: 0;

        margin-bottom: 4em;   
    }

    .image{
        width: 100%;
        height: auto;
    }

}