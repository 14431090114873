#root{
    max-width: 1500px;
    margin-top: 84px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.item{
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 20px !important;
    padding: 0;
} 

@media only screen and (max-width: 1200px) {
    #root{
        width: 100%;
    }  
}


@media only screen and (max-width: 600px) {

    #root{
        margin: 0;
        margin-bottom: 64px;
        padding: 0 !important;
        width: 100%;
    }

    .item{
        margin: 0;
        padding: 0 !important;
        width: 100%;
    }
}