#navbar{
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 4em;

    display: flex;
    align-items: center;

    background-color: #0A0A0B;

    box-shadow: 0px 5px 7px -2px #0A0A0B;
    z-index: 999;
}

#homeItem{
    margin-left: 4em;
    margin-right: auto;
}

#homeItem>*{
    width: auto;
    max-height: 36px;
}

#lastMenuItem{
    margin-right: 4em;
}

.menuItem{
    margin-left: 2em;

    transition: ease-in 0.1s;
    text-decoration: none;
}

.menuItem>*{
    margin: 0;
    padding: 0;
    transform: scale(1.1);
    color: white;
}

.menuItem>p{
    font-family: Merriweather Sans;
}

.menuItem:hover{
    transform: translateY(-3px);
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    #navbar{
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;

        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    #homeItem{
        margin-left: 0;
        margin-right: 0;
    }

    #lastMenuItem{
        margin-right: 0;
    }
}