#root{
    padding-top: 64px;
    width: 100%;
    display: flex;
    justify-content: flex-start;


    max-height: calc(100vh - 64px);
    overflow: hidden;


}

#imageSection{
    height: calc(100vh - 64px);
    max-width: min-content;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    overflow: hidden;
}

#textSection{
    width: 100%;
    padding: 90px;
    max-width: calc(100vh - 64px);
    overflow-y: auto;
}

.innerContainer{
    max-width: 700px;
}

.title{
    color: #DADADA;
    font-size: 28pt;
    margin-bottom: 5px;
    text-align: left;
    margin-left: 20px;

    font-family: Merriweather;


}

.subTitle{
    color: #BEBEBE;
    margin-top: 0;
    margin-left: 20px;
    font-size: 10pt;
    text-align: left;

    font-family: Merriweather Sans;
}

.description{
    color: #BEBEBE;
    margin-top: 50px;
    margin-left: 20px;
    font-size: 16pt;
    text-align: left;

    font-family: Merriweather Sans;
}

.image{
    display: block;
    width: auto;
    height: 100%;
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: #141416;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #0A0A0B;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

@keyframes FadeIn {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
}

@media only screen and (max-width: 1200px) {

    #imageSection{
        height: calc(100vh - 64px);
        max-width: min-content;
        width: 40%;
        display: flex;
        justify-content: center;
        padding: 0;
        height: calc(100vh - 64px);
    }
    
    #textSection{
        width: 60%;
        padding: 0;
        max-width: calc(100vh - 64px);
        overflow: scroll;
    }
    
    .image{
        display: block;
        width: auto;
        height: 100%;
        padding: 0;
    }

    .title{
        margin-left: 50px;
    }

    .subTitle{
        margin-left: 50px;
    }

    .description{
        margin: 50px;
    }

}

@media only screen and (max-width: 600px) {
    #root{
        padding-top: 0px;
        padding-bottom: 64px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        max-height: none;
    }

    #imageSection{
        width: 100%;
        max-width: none;
        display: flex;
        justify-content: center;
    }

    #textSection{
        max-width: 100vw;
        width: 100%;
        padding: 0px;
    }

    .title{
        margin-left: 50px;
    }

    .subTitle{
        margin-left: 50px;
    }

    .description{
        margin: 50px;
    }

    .image{
        display: block;
        object-fit: cover;
        min-width: 100vw;
        max-width: 100%;
        width: auto;
        height: auto;
        
    }
}